import React, { Component } from 'react'
import {
    Grid,
    TextField,
    Typography,
    FormControl,
    RadioGroup,
    FormControlLabel,
    DialogTitle,
    Radio,
    Box,
    Dialog,
    Button,
    DialogContent,
    DialogActions,
} from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { getCart, saveCart, calcularValorTotalPedido, calcularValorTotalItensPedido } from "../../../utils/PedidoUtil";
import Utils from "../../../utils/Utils";
import AuthService from "../../../services/AuthService";
import TempoPreparo from "../../generic/TempoPreparo"
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import MoodIcon from '@material-ui/icons/Mood';
import AlertDialog from "../../generic/AlertDialog"
import Grow from '@material-ui/core/Grow';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { getPerfile } from '../../../utils/PedidoUtil';

export class FormaPagamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            formaPagamentoStr: "",
            formaPagamentoList: [],
            buscandoFormasDePagamento: false,
            cupomDesconto: '',
            cupomValido: '',
            msgRetornoCupomDesconto: '',
            openMsgRetornoCupom: false,
            openTroco: false,
            troco: null,
            errorTroco: null
        }

        this.onClickAplicarCupomDesconto = this.onClickAplicarCupomDesconto.bind(this)
        this.handleChangeCupomDesconto = this.handleChangeCupomDesconto.bind(this)
        this.removerDescontoCart = this.removerDescontoCart.bind(this)
    }

    componentWillMount() {
        //remove qualquer desconto do cart
        this.removerDescontoCart()
    }

    removerDescontoCart() {
        let cart = getCart()
        cart.pedido.valorDesconto = 0
        cart.cupomDesconto = null
        saveCart(cart)
    }

    componentDidMount() {
        let service = new AuthService();
        this.setState({ buscandoFormasDePagamento: true })
        service.get("/formaPagamento/list?ativo=true")
            .then(retorno => {
                this.setState({ buscandoFormasDePagamento: false })
                this.setState({ formaPagamentoList: retorno })
            }).catch(err => {
                this.setState({ buscandoFormasDePagamento: false })
                console.error("Falha ao buscar formas de pagamento", err)
            })

        this.props.handleChoosePayment({});
    }


    onClickAplicarCupomDesconto() {
        this.removerDescontoCart()
        let service = new AuthService()
        let cupom = this.state.cupomDesconto
        this.setState({ msgRetornoCupomDesconto: '' })

        if (cupom !== '') {
            let cart = getCart()

            const { valorTaxaEntrega } = this.props;
            cart.pedido.valorTaxaEntrega = valorTaxaEntrega

            service
                .post(`/cupomDesconto/aplicar/${this.state.cupomDesconto}`, cart.pedido)
                .then(resp => {

                    if (resp.cupomDesconto.tipoCupom !== 'DELIVERY') {
                        this.setState({ cupomValido: 'N' })
                        this.setState({ msgRetornoCupomDesconto: "Cupom válido somente para uso no estabelecimento" })
                        return;
                    }

                    this.setState({ cupomValido: 'S' })

                    cart.pedido.valorDesconto = resp.valorDesconto
                    cart.pedido.cupomDesconto = resp.cupomDesconto
                    saveCart(cart)
                    this.setState({ cart })
                    this.setState({ msgRetornoCupomDesconto: 'Cupom de desconto válido' })
                }

                ).catch(err => {
                    this.setState({ cupomValido: 'N' })

                    if (err.response !== undefined) {
                        err.response.json()
                            .then(elem => {
                                console.log(elem.errorMessage)
                                this.setState({ msgRetornoCupomDesconto: elem.errorMessage })
                            });
                    }
                })
        } else {
            this.setState({ cupomValido: '' })
        }
    }

    handleChoosePayment(event) {
        this.setState({ formaPagamentoStr: event.target.value })
        let formaPagamentoObj = JSON.parse(event.target.value)

        this.props.handleChoosePayment(formaPagamentoObj);

        if (formaPagamentoObj.tipo === "DINHEIRO") {
            this.handleOpenTroco();
        } else {
            this.handleSemTroco()
        }
    }

    handleChangeCupomDesconto(event) {
        let cupom = String(event.target.value).toUpperCase()
        this.setState({ cupomDesconto: cupom })

        if (!this.state.cupomDesconto) {
            this.setState({ cupomValido: '' })
        }
    }

    handleCloseTroco = () => {
        this.setState({ openTroco: false })
    }

    handleOpenTroco = () => {
        this.setState({ openTroco: true })
    }

    handleChangeTroco = (event) => {
        this.setState({ troco: event.target.value })
    }

    handleSaveTroco = () => {
        let { troco, formaPagamentoList } = this.state;
        let cart = getCart()
        const { valorTaxaEntrega } = this.props

        let total = calcularValorTotalPedido(cart.pedido) + valorTaxaEntrega

        if (troco < total) {
            this.setState({ errorTroco: "Você não pode atribuir um valor menor que o preço total" })
            return
        }

        this.props.handleSaveTroco(troco);
        this.handleCloseTroco();

        let index = formaPagamentoList.findIndex(el => el.tipo === "DINHEIRO");
        this.props.handleChoosePayment(formaPagamentoList[index]);

    }

    handleSemTroco = () => {
        this.setState({ troco: 0 });
        this.props.handleSaveTroco(0);
        this.handleCloseTroco();
    }

    render() {
        const { formaPagamentoList,
            formaPagamentoStr,
            buscandoFormasDePagamento,
            cupomValido,
            msgRetornoCupomDesconto,
            openTroco,
            troco,
            errorTroco
        } = this.state;

        const empresa = getPerfile().empresa;
        const cart = getCart()
        const { tipoDelivery, valorTaxaEntrega } = this.props;

        cart.pedido.tipoDelivery = tipoDelivery
        cart.pedido.valorTaxaEntrega = valorTaxaEntrega
        cart.pedido.troco = troco

        return (
            <Grid
                spacing={2}
                container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12}>
                    <Typography align="center">
                        <strong>Selecione o método de pagamento</strong>
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    {buscandoFormasDePagamento ? "buscando formas de pagamento..." : null}
                    {!buscandoFormasDePagamento && formaPagamentoList.length === 0 ? "ops... nenhuma forma de pagamento configurada" : null}

                    <FormControl component="fieldset">
                        <RadioGroup value={formaPagamentoStr} name="formaPagamentoStr" onChange={(event) => this.handleChoosePayment(event)}>
                            {
                                formaPagamentoList.map(elem =>
                                    <FormControlLabel key={elem.id} value={JSON.stringify(elem)} control={<Radio />} label={elem.descricao} />
                                )
                            }

                        </RadioGroup>
                    </FormControl>

                </Grid>

                <Grid item xs={12} >
                    <TextField
                        label="Alguma observação no seu pedido?"
                        name="obs"
                        fullWidth
                        onChange={event => this.props.handleChangeObs(event)}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid
                        spacing={2}
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">

                        <Grid item xs={12}>

                            {!this.state.showInputCupom ? (
                                <Box textAlign="center">
                                    <Chip
                                        onClick={() => this.setState({ showInputCupom: true })}
                                        label="Adicionar um cupom de desconto?"
                                        variant="outlined" />
                                </Box>
                            ) : (
                                <Grid
                                    spacing={2}
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center">

                                    <Grid item xs={10} align="left">
                                        <Grow in={true}>
                                            <TextField
                                                label="Informe seu cupom"
                                                name="cupomDesconto"
                                                value={this.state.cupomDesconto}
                                                fullWidth
                                                onChange={event => this.handleChangeCupomDesconto(event)}
                                                variant="outlined"
                                                inputProps={{ maxLength: 20 }} />
                                        </Grow>

                                    </Grid>

                                    <Grid item xs={2} align="center">
                                        <Grow in={true} timeout={1000}>
                                            <IconButton onClick={this.onClickAplicarCupomDesconto}>
                                                <ThumbUpIcon />
                                            </IconButton>
                                        </Grow>
                                    </Grid>
                                </Grid>
                            )}

                        </Grid>

                        <Grid item xs={12} align="left">
                            {cupomValido !== '' ? (
                                <Chip
                                    onClick={() => this.setState({ openMsgRetornoCupom: true })}
                                    icon={cupomValido === 'S' ? <MoodIcon /> : <MoodBadIcon />}
                                    label={cupomValido === 'S' ? 'Legal! Olha o descontão aí.' : 'Cupom é inválido. Clique para saber o motivo'}
                                    color={cupomValido === 'S' ? 'primary' : 'secondary'}
                                    variant="outlined"
                                />) : null}

                            <AlertDialog
                                open={this.state.openMsgRetornoCupom}
                                handleClose={() => this.setState({ openMsgRetornoCupom: false })}
                                title=""
                                content={msgRetornoCupomDesconto} />
                        </Grid>
                    </Grid>
                </Grid>


                {formaPagamentoStr ? (

                    <React.Fragment>

                        <Grid item xs={12} >

                            <Grow in={true} timeout={1000}>
                                <div>
                                    <Table aria-label="Totais" size="small">

                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>
                                                    Totais
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <Typography>Itens</Typography>
                                                </TableCell>
                                                <TableCell align="right">{Utils.formatNumber(calcularValorTotalItensPedido(cart.pedido))} </TableCell>
                                            </TableRow>


                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {tipoDelivery === "RETIRAR_LOCAL" ? (
                                                        <Typography style={{ opacity: "0.3" }}>sem taxa</Typography>
                                                    ) : (<Typography>Taxa de entrega</Typography>)}
                                                </TableCell>

                                                <TableCell align="right">{tipoDelivery === "RETIRAR_LOCAL" ? "" : ` ${Utils.formatNumber(valorTaxaEntrega)}`}</TableCell>
                                            </TableRow>

                                            {
                                                cart.pedido.troco && cart.pedido.troco > 0 ? (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <Typography>Troco para</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">{Utils.formatNumber(cart.pedido.troco)}</TableCell>
                                                    </TableRow>
                                                ) : null
                                            }

                                            {
                                                cart.pedido.valorDesconto && cart.pedido.valorDesconto > 0 ? (
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <Typography>Desconto</Typography>
                                                        </TableCell>
                                                        <TableCell align="right">{Utils.formatNumber(cart.pedido.valorDesconto)} </TableCell>
                                                    </TableRow>
                                                ) : null
                                            }

                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    <strong><Typography>Total</Typography></strong>
                                                </TableCell>
                                                <TableCell align="right"> <strong>{Utils.formatNumber(calcularValorTotalPedido(cart.pedido))} </strong></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    {(empresa.tempoMinPreparo || empresa.tempoMaxPreparo !== 0) &&
                                        tipoDelivery === 'ENTREGAR' ? (
                                        <Box p={1}>
                                            <Alert icon={false} severity="info">
                                                <TempoPreparo />
                                            </Alert>
                                        </Box>
                                    ) : null}

                                    {empresa.tempoRetirada !== 0 && tipoDelivery === 'RETIRAR_LOCAL' ? (
                                        <Box p={1}>
                                            <Alert icon={false} severity="info">
                                                <Typography>Tempo para retirada no local {empresa.tempoRetirada}m</Typography>
                                            </Alert>
                                        </Box>
                                    ) : null}

                                    {empresa.tempoRetirada !== 0 && tipoDelivery === 'CONSUMIR_LOCAL' ? (
                                        <Box p={1}>
                                            <Alert icon={false} severity="info">
                                                <Typography>Tempo de preparo - {empresa.tempoRetirada}m</Typography>
                                            </Alert>
                                        </Box>
                                    ) : null}
                                </div>
                            </Grow>
                        </Grid>

                    </React.Fragment>


                ) : null}

                <Dialog
                    open={openTroco}
                    onClose={this.handleCloseTroco}
                    align='center'
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle >
                        Opa, vai precisar de troco para?
                    </DialogTitle >

                    <DialogContent>

                        <Box display="flex" justifyContent="center">
                            <TextField
                                helperText={errorTroco}
                                error={errorTroco !== null}
                                name='dinheiro'
                                variant='outlined'
                                fullWidth
                                type='number'
                                placeholder={`Ex: ${Utils.formatNumber(100)}`}
                                onChange={e => this.handleChangeTroco(e)}
                            />
                        </Box>

                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={this.handleSemTroco}>Sem troco</Button>

                        <Button color='secondary' variant="contained"
                            onClick={this.handleSaveTroco}
                            endIcon={
                                <CheckCircleOutlineSharpIcon />
                            }>
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>


            </Grid>

        )
    }

}


export default FormaPagamento
