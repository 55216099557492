import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, IconButton, ButtonBase, Avatar, useMediaQuery, useTheme } from '@material-ui/core';
import AuthService from '../../../services/AuthService';
import Utils from '../../../utils/Utils'
import { useHistory } from 'react-router-dom';
import ItemsCarousel from 'react-items-carousel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useDispatch } from 'react-redux'
import { PromoBadge } from '../../generic/PromoBadge';

const useStyles = makeStyles((theme) => ({

    grid: {
        display: 'grid',
        gridGap: theme.spacing(),
        minHeight: 130,
        maxHeight: 130,
    },

    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },

    button: {
        overflow: 'hidden',
        backgroundColor: theme.palette.personal.paperBackground,
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        transition: theme.transitions.create('background-color', {
            duration: theme.transitions.duration.shortest,
        }),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.spacing(),
        backgroundClip: 'padding-box',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        padding: '16px',
        height: '100%',
        minHeight: "70px",

        '&:hover': {
            textDecoration: 'none',
            backgroundColor: theme.palette.action.hover,

            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    listProdutos: {
        width: '100%',
        maxHeight: '100px',
    },

    item: {
        overflowWrap: 'anywhere',
        fontWeight: '500',
        textTransform: 'initial',
    },

    itemDescricao: {
        overflowWrap: 'anywhere',
        textTransform: 'initial',
        marginTop: theme.spacing(0.5),
        lineHeight: 1.2,
    },

    valor: {
        color: theme.palette.primary.main,
        whiteSpace: 'nowrap',
        textTransform: 'initial',
        lineHeight: '1em',
    },

    productName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 500,
        maxWidth: '100%',
        padding: '8px 0',
        lineHeight: '1em',
    },

    mediaDefault: {
        width: '112px',
        height: '112px',
    }

}))

function PainelPromocao() {
    const theme = useTheme()
    const useStyles = makeStyles((theme) => ({
        promoTitle: {
            color: theme.palette.primary.main,
            textTransform: 'uppercase',
            fontFamily: "Bebas Neue",
            fontSize: '1.4em'
        },
        chevronBtn: {
            boxShadow: '0 0 10px -6px #000',
            backgroundColor: theme.palette.personal.paperBackground,
        },
    }))

    const dispatch = useDispatch()
    const chevronWidth = 8
    const [produtos, setProdutos] = useState([])
    const classes = useStyles()
    const history = useHistory()
    const [activeItemIndex, setActiveItemIndex] = useState(0)
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const numberOfCards = isDesktop ? 3.2 : 1.5

    useEffect(() => {
        const service = new AuthService()

        /** SO CARREGA OS PRODUTOS SE ESTIVER COM TOKEN */
        if (!service.loggedIn()) {
            return
        }

        service
            .get("/produto/listPromocao")
            .then(retorno => {
                setProdutos(retorno)
            }).catch(err => history.replace("/errors"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickItemPromocao = (produto) => {
        dispatch({ type: 'OPEN_PRODUCT', product: produto })
    }

    return produtos && produtos.length > 0 && (
        <>
            <Box textAlign="center" pt={2}>
                <Typography
                    variant="h6"
                    component="h2"
                    display="block"
                    className={classes.promoTitle} >
                    Em promoção
                </Typography>
            </Box>

            <Box pt={1} pb={1}>
                <div style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        gutter={8}
                        slidesToScroll={1}
                        chevronWidth={30 + chevronWidth}
                        numberOfPlaceholderItems={3}
                        numberOfCards={numberOfCards}
                        activeItemIndex={activeItemIndex}
                        requestToChangeActive={setActiveItemIndex}
                        leftChevron={
                            isDesktop && (
                                <IconButton size="small" className={classes.chevronBtn}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            )
                        }
                        rightChevron={
                            isDesktop && (
                                <IconButton size="small" className={classes.chevronBtn}>
                                    <ChevronRightIcon />
                                </IconButton>
                            )
                        }>

                        {produtos.map((produto, index) => (
                            <CardPromocao produto={produto} key={index} onClick={() => onClickItemPromocao(produto)} />
                        ))}
                    </ItemsCarousel>
                </div>
            </Box>
        </>
    )
}

function CardPromocao({ produto, onClick }) {
    const classes = useStyles()
    const hasImage = produto.imagens && produto.imagens.length > 0

    return (
        <ButtonBase
            focusRipple
            className={classes.button}
            onClick={() => onClick(produto)}>
            {hasImage ? (
                <Avatar
                    variant="rounded"
                    className={classes.large}
                    src={produto.imagens[0].thumbnailUrl} />
            ) : (
                <Avatar
                    variant="square"
                    className={classes.large}
                    src={`/assets/images/iconsCircles/${produto?.grupoProduto?.tipoGrupoProduto?.imagem}`} />
            )}

            {produto.promocaoAtiva && <PromoBadge />}

            <Typography className={classes.productName}>{produto.nome}</Typography>

            {!produto.preco ? (
                <ComplementsValue produto={produto} />
            ) : (
                <Typography className={classes.valor}>
                    {Utils.formatNumber(produto.preco)}
                </Typography>
            )}
        </ButtonBase>
    )
}

function ComplementsValue({ produto }) {
    const classes = useStyles()

    if (!produto.complementos.length) {
        return (
            <Typography className={classes.valor}>
                Ver opções
            </Typography>
        )
    }

    if (produto.complementos.length === 1) {
        return (
            <Typography className={classes.valor}>
                1 complemento
            </Typography>
        )
    }

    return (
        <Typography className={classes.valor}>
            {produto.complementos.length} complementos
        </Typography>
    )
}


export default PainelPromocao;